<template>
  <v-card-actions class="pa-4 justify-space-between">
    <div>
      <v-btn
        v-if="
          String(inspectionType) !== '1' &&
          principalId !== 0 &&
          can_add_inspection &&
          canDelete_permission
        "
        color="primary"
        elevation="0"
        outlined
        @click="deletePrincipal"
      >
        ลบความเสียหาย
      </v-btn>
      <template v-if="step === 1 && (svgStep === 1 || isBridgeCulvert)">
        <v-autocomplete
          :value="componentId"
          label="Component อื่นๆ"
          :items="localOtherParts"
          item-text="displayName"
          item-value="id"
          :disabled="getBridge.bridge_segment[0].seq === null"
          @change="changeOtherComponent"
        >
        </v-autocomplete>
      </template>
    </div>
    <div>
      <v-btn
        v-if="step !== 2"
        :disabled="step === 1 && (!sectorId || !componentId)"
        color="primary white--text"
        elevation="0"
        @click="next"
      >
        ถัดไป
      </v-btn>
      <v-btn
        v-if="step === 2 && can_add_inspection && canEdit_permission"
        :disabled="
          loading ||
          !stepTwoFormSuccess ||
          (String(inspectionType) === '3' &&
            (!stepTwoDimensionSuccess || !elementNoWeight))
        "
        color="primary white--text"
        elevation="0"
        @click="saveInspectionDamage"
      >
        บันทึก
        <v-progress-circular
          v-if="loading"
          class="ml-2"
          indeterminate
          size="16"
        />
      </v-btn>
    </div>
    <v-dialog
      v-model="deletePrincipalDialog"
      :max-width="!deleteResult ? 600 : 250"
      persistent
    >
      <v-card>
        <v-card-text class="my__v-card-text">
          <div
            v-if="!deleteResult"
            class="black--text text-center py-5"
            style="font-size: 1.5rem"
          >
            <v-icon class="mb-2" color="yellow" style="font-size: 3.75rem">
              mdi-alert-outline
            </v-icon>
            <br />
            ยืนยันการลบความเสียหาย
          </div>
          <div
            v-else-if="deleteResult === 'success'"
            class="pa-4 pb-1 text-center"
          >
            <v-icon class="green--text" style="font-size: 57px">
              mdi-check-circle-outline
            </v-icon>
            <div
              class="black--text font-weight-bold my-4"
              style="font-size: 1.5rem"
            >
              การลบข้อมูลสำเร็จ
            </div>
            <div class="progress-green">
              <div class="color-green"></div>
            </div>
          </div>
          <div v-else class="pa-4 pb-1 text-center">
            <v-icon class="red--text" style="font-size: 57px">
              mdi-check-circle-outline
            </v-icon>
            <div
              class="black--text font-weight-bold my-4"
              style="font-size: 1.5rem"
            >
              การลบข้อมูลไม่สำเร็จ
            </div>
            <div class="progress-red">
              <div class="color-red"></div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn
            v-if="deleteResult !== 'success'"
            elevation="0"
            outlined
            style="flex: 1 1 0"
            @click="closeDeleteDialog"
          >
            ยกเลิก
          </v-btn>
          <v-btn
            v-if="deleteResult !== 'success'"
            color="blue white--text"
            elevation="0"
            style="flex: 1 1 0"
            @click="confirmDeletePrincipal"
          >
            ลบ
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="successPrincipalDialog" max-width="600">
      <v-card>
        <v-card-text class="my__v-card-text">
          <div class="black--text text-center py-5" style="font-size: 1.5rem">
            <v-icon class="mb-2" color="success" style="font-size: 3.75rem">
              mdi-check-circle-outline
            </v-icon>
            <br />
            บันทึกสำเร็จ
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn
            class="px-1 newForm blue--text text-decoration-underline"
            text
            @click="newPrincipalItem"
          >
            + เพิ่มความเสียหายอื่น
          </v-btn>
          <v-btn
            class="closeForm"
            elevation="0"
            outlined
            @click="closeSuccessDialog"
          >
            ปิด
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card-actions>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'StepActions',
  data: () => ({
    loading: false,
    deletePrincipalDialog: false,
    deleteResult: null,
    successPrincipalDialog: false
  }),
  mounted () {
    console.log(this.bridgeOverview)
  },
  methods: {
    deletePrincipal () {
      this.deletePrincipalDialog = true
    },
    changeOtherComponent (e) {
      console.warn(e)
      let parts = {
        sectorID: this.sectorId,
        sectorNo: this.sectorNo,
        componentID: e,
        componentNo: '1',
        otherComponent: true
      }
      if (this.isBridgeCulvert) {
        parts = {
          sectorID: this.localOtherParts.find((obj) => obj.id === e).sector_id,
          sectorNo: 1,
          componentID: e,
          componentNo: '1',
          otherComponent: true
        }
      }
      this.$store.commit('inspectionDamageDialog/SET_PRINCIPAL_SVG', parts)
    },
    next () {
      this.$store.commit('inspectionDamageDialog/STEP_INCREMENT')
      if (this.step === 2) {
        this.$store
          .dispatch('inspectionDamageDialog/fetchComponentDimension', {
            component_id: this.componentId
          })
          .then(() => {
            this.$store.dispatch('inspectionDamageDialog/fetchDamageMapping', {
              component_id: this.componentId
            })
            if (String(this.inspectionType) !== '2') {
              this.$store.dispatch(
                'inspectionDamageDialog/fetchElementNoWeight',
                { component_id: this.componentId }
              )
            }
          })
      }
    },
    async saveInspectionDamage () {
      this.loading = true
      const saveDamagePromise = await this.$store.dispatch(
        'inspectionDamageDialog/saveInspectionDamage'
      )
      let saveDimensionPromise
      if (String(this.inspectionType) !== '2') {
        saveDimensionPromise = await this.$store.dispatch(
          'inspectionDamageDialog/saveDimension'
        )
      } else {
        saveDimensionPromise = true
      }
      if (saveDamagePromise && saveDimensionPromise) {
        this.successPrincipalDialog = true
      }
      this.loading = false
    },
    closeSuccessDialog () {
      this.successPrincipalDialog = false
      this.$store.commit('inspectionDamageDialog/STEP_RESET')
      this.$store
        .dispatch('inspectionDamageDialog/refreshInspection')
        .then(() => {
          this.$nextTick(() => {
            this.$store.dispatch('inspectionDamageDialog/restoreScrollDistance')
          })
        })
    },
    async confirmDeletePrincipal () {
      this.deleteResult = (await this.$store.dispatch(
        'inspectionDamageDialog/deletePrincipal'
      ))
        ? 'success'
        : 'fail'
      setTimeout(this.closeDeleteDialog, 3000)
    },
    newPrincipalItem () {
      this.successPrincipalDialog = false
      this.$store.dispatch('inspectionDamageDialog/closeAndNewDamageForm')
      this.$store.dispatch('inspectionDamageDialog/fetchInspectionDialog')
    },
    closeDeleteDialog () {
      this.deletePrincipalDialog = false
      if (this.deleteResult === 'success') {
        this.$store.commit('inspectionDamageDialog/STEP_RESET')
        this.$store.commit('inspectionDamageDialog/SVG_STEP', 0)
        this.$store.dispatch('inspectionDamageDialog/fetchInspectionDialog')
      }
    }
  },
  computed: {
    ...mapState('inspectionDamageDialog', {
      inspectionType: 'inspectionType',
      step: 'step',
      svgStep: 'svgStep',
      principalId: 'principalId',
      elementNoWeight: 'elementNoWeight'
    }),
    ...mapState('inspectionDamageDialog', {
      componentId: 'componentId',
      sectorId: 'sectorId',
      sectorNo: 'sectorNo',
      otherParts: 'otherParts'
    }),
    ...mapGetters('inspectionDamageDialog', {
      getBridge: 'getBridge'
    }),
    ...mapState('inspectionDamageDialog', {
      stepTwoFormSuccess: 'stepTwoFormSuccess',
      stepTwoDimensionSuccess: 'stepTwoDimensionSuccess'
    }),
    ...mapGetters('bridgeDetail', {
      canEdit_permission: 'canEdit_permission',
      canDelete_permission: 'canDelete_permission'
    }),
    ...mapState('bridgeDetail', {
      bridgeOverview: 'bridgeOverview'
    }),
    ...mapGetters({ can_add_inspection: 'can_add_inspection' }),
    localOtherParts () {
      return this.otherParts.map((item) => ({
        ...item,
        displayName: `[${item.id}] ${item.name}`
      }))
    },
    isBridgeCulvert () {
      return (
        this.bridgeOverview.bridge_part[0][0].bridge_strurture_id === '1' ||
        this.bridgeOverview.bridge_part[0][0].bridge_structure_id === '3'
      )
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 600px) {
  .closeForm,
  .newForm {
    flex: 1 1 0;
  }
}

@media screen and (max-width: 600px) {
  .closeForm {
    flex: 0 1 100px;
  }

  .newForm {
    flex: 0 1 auto;
  }
}
</style>
